.react-flow__edge .react-flow__edge-textbg {
  fill: #000;
}
.react-flow__edge .react-flow__edge-text {
  fill: rgb(255, 255, 255);
  font-weight: bold;
}
.edgebutton {
  background-color: #000;
}
.edgebutton.failure {
  background-color: rgb(230, 55, 87);
}
.edgebutton.success {
  background-color: rgb(0, 210, 122);
}
.react-flow__edge.react-flow__edge-custom.success .react-flow__edge-path {
  stroke: rgb(0, 210, 122);
}
.react-flow__edge.react-flow__edge-custom.danger .react-flow__edge-path {
  stroke: rgb(230, 55, 87);
}
.react-flow__edge.react-flow__edge-custom.success .react-flow__edge-textbg {
  fill: rgb(0, 210, 122);
}
.react-flow__edge.react-flow__edge-custom.danger .react-flow__edge-textbg {
  fill: rgb(230, 55, 87);
}
.reactflow_custom_node {
  width: 209px;
}
.node-tag {
  left: 0;
  bottom: 100%;
  height: 50;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.react-flow__attribution {
  display: none;
}
.react-flow__handle.custom {
  background: transparent;
  transform: none;
  border-radius: 0;
  border: none;
}
.react-flow__handle.connectablestart.connectionindicator.connecting:hover {
  background: transparent;
}
.react-flow__handle.connectablestart.connectionindicator:hover,
.react-flow__handle.connectableend.connectionindicator:hover,
.react-flow__handle.connectablestart.connectionindicator.connecting.valid:hover {
  background: #000;
}
.react-flow__handle.selectedNode.connectablestart.connectionindicator:hover,
.react-flow__handle.selectedNode.connectableend.connectionindicator:hover {
  background: transparent;
}
.react-flow__handle.notSelectedNode.connectableTarget {
  background: #000;
}
.sourceConnectLeftLine {
  top: 30px;
  bottom: 0px;
}
.sourceConnectBottomtLine {
  bottom: -4px;
  left: 0;
}
.sourceConnectToptLine {
  top: -4px;
  left: 0;
}
.targetConnectRightLine {
  top: 35px;
  bottom: 0px;
  left: 100%;
  width: 6px;
}
.targetConnectRightLine .react-flow__handle {
  right: 0;
}
.targetConnectBottomtLine {
  bottom: -4px;
  right: 0;
  left: unset;
}
.targetConnectTopLine {
  top: 26px;
  right: 0;
  left: unset;
}
