.br-nr-400 {
  border-color: #b6c1d2 !important;
  border-right-color: transparent !important;
}
.br-nr-500 {
  border-color: #9da9bb !important;
  border-right-color: transparent !important;
}
.br-nr-600 {
  border-color: #748194 !important;
  border-right-color: transparent !important;
}
.br-nr-700 {
  border-color: #5e6e82 !important;
  border-right-color: transparent !important;
}
.react-paginate-container {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
  padding: 0;
}
.react-paginate-container li a {
  width: 40px;
  height: 40px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5e6e82 !important;
}
.react-paginate-container li.active a {
  background: #5541d7;
  color: #fff !important;
}
.react-paginate-container li.disabled a {
  opacity: 0.4;
}
.react-paginate-container li a:hover {
  background: #5541d7;
  color: #fff !important;
}
.react-paginate-container li.disabled a:hover {
  color: #5e6e82 !important;
  background: #fff;
  cursor: default;
}
// custom react-select styles
.react-select__control {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid #b6c1d2 !important;
  border-radius: 0 !important;
}
.react-select__value-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-weight: 400 !important;
}
.react-select__single-value {
  color: #808080 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin: 0 !important;
}
.react-select__indicator-separator {
  display: none !important;
}
.react-select__menu {
  font-size: 14px;
}

.react-datetime-picker__wrapper,
.custom-rounded-shadow {
  border-radius: 4px;
  border: none;
  box-shadow: 0 0 4px 0px #ccc;
  padding: 0.25rem 1rem;
}
.modal-dialog.asset_modal.modal-lg {
  max-width: 900px;
  width: 100%;
}

// screenshot slider styles
.screenshots_slider {
  height: 80vh;
  .carousel {
    height: 100%;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 2rem;
    .carousel-indicators {
      margin-bottom: 0.5rem;
    }
    .carousel-inner {
      height: 100%;
      .carousel-item {
        height: 100%;
      }
    }
  }
  .carousel > a {
    width: 3rem;
  }
}
